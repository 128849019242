.header--container-submenu{
    width: 100%;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: var(--color-neutral-light-II);

    .submenu--container-access{
        width: 30%;
        display: flex;
        .root-130{
            color: var(--color-neutral-light-II);
        background-color: transparent;
        height: 40px;
        // &:hover{
        //     color: var(--color-neutral-light-II);
        //     background-color: rgba($color: #000000, $alpha: 0.1);
        // }
        }
        
    }

    .submenu--container-modal{
        width: 70%;
        // display: flex;
        .ms-FocusZone{
            // color: var(--color-neutral-light-II);
            background-color: var(--color-neutral-light-II);
            height: 40px;
            &:hover{
                color: var(--color-neutral-light-II);
                // background-color: rgba($color: #000000, $alpha: 0.1);
            }
        }
    }

    .submenu--container-modal{
        align-items: center;
        button{
            background: none;
            border: none;
            font-size: var(--font-size-quarternary);
            font-weight: 400;
            color: var(--color-primary);
            &:hover{
                background-color: var(--color-neutral-light-I);
            }
        }
    }

    .submenu--container-access{
        .submenu--container-lang{
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        
        .root-47, div{
            background-color: var(--color-neutral-light-II);
            &:hover{
                color: var(--color-primary);
            }
        }
        .root-40{
            height: 40px;
        }
        .ms-Button{
            background-color: transparent;
            height: 40px;
            &:hover{
                color: var(--color-primary);
                background-color: rgba($color: #000000, $alpha: 0.1);
            }
        }
        .ms-FocusZone{
            height: 40px;
        }
        i{
            color: var(--color-primary);
            font-weight: 500;
        }
        .root-129{
            background-color: transparent;
            color: rgb(32, 31, 30);
            &:hover {
                color: var(--color-primary);
                background-color: rgba($color: #000000, $alpha: 0.1);
            }
        }
        .root-106{
            height: 40px;
        }
    }
    
}
.w83{width: 83%;}

.menu-items{
    .ms-FocusZone{
        padding: 0px 14px 0px 10px;
    }
    .ms-OverflowSet-item{
        // width: 100px;
        white-space: normal;
        button{
            color: var(--color-neutral-dark-III);
        }
    }
}