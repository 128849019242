.inputFile--container{
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 5px 0;
    box-sizing: border-box;
    label{
        width: 100%;
        padding: 6px 0;
        font-size: var(--font-size-tertiary);
    }
    input{
        margin: 0 0 20px 0;
        padding: 2px 5px;
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        transition: all 0.3s;
        color: var(--color-neutral-dark-II);
        border: 1px solid var(--color-neutral-light-I);
        // margin-bottom: 20px;
        &:hover{
            transition: all 0.3s;
            border: 2px solid var(--color-neutral-base);
        }
        &:focus{
            outline: none;
            border: none;
            transition: all 0.3s;
            box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);
        }
    }
    input[type="checkbox"]{
        width: 7%;
        margin-left: 1px;
    }
    .inputFielt--container-input{
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        span{
            top: 0;
            right: 0;
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            // position: absolute;
            align-items: center;
            transition: all 0.3s;
            justify-content: center;
            box-sizing: border-box;
            color: var(--color-neutral-base);
            border: 1px solid var(--color-neutral-light-I);
            i{
                font-size: 20px;
                user-select: none;
            }          
            &:hover{
                transition: all 0.3s;
                background: var(--color-neutral-light-I);
            }
        }
    }
    .est{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .politics{
            font-size: var(--font-size-pentanary);
            color: var(--color-primary);
            font-weight: 600;
        }
        .content{
            float: left;
        }
    }
}

.polits{
    li{
        list-style: initial;
    }
    
}
.ms-TeachingBubble-body{
     margin-left: 15px;
     margin-bottom: 0px;   
}

.space-bottom{
    input{
    margin: 0 0 0px 0;
    }
}

.ms-Callout-main, .ms-Callout-beak{
    background: var(--color-primary);
    .ms-Button-label{
        color: var(--color-primary);

    }
    .ms-Button{
        background: white;
    }
}
.teching-bubble{
    .ms-Button{
        background: red;
    }
}
