.container--login{
    position: fixed;
    top: 0;
    left: 0;
    width: 55vw;
    height: 100%;
    filter: grayscale(50%);
    background: url('../../../assets/img/background.webp');
    clip-path: polygon(100% 96%, 100% 100%, 0% 100%, 0% 0%, 35% 0%, 34.8% 2%, 35% 8%, 36% 15%, 38% 22%, 
                        40% 26%, 41% 28%, 43% 31%, 46% 34%, 54% 41%, 62% 47%, 68% 51%, 73% 55%, 80% 60%, 
                        86% 65%, 92% 71.8%, 96% 78%, 97.2% 81%, 99.2% 89%);
}

.login--fond-degrade{
    width: 100%;
    max-width: 1700px;
    height: revert;
    display: flex;
    padding: 1.5% 3%;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-neutral-light-III);
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    .login--fond-base{
        width: 90%;
        max-width: 320px;
        min-width: 300px;
        height: 100%;
        min-height: 300px;
        z-index: 10;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-content: space-between;
        background-color: var(--color-neutral-light-III);
        .login--container-header{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        a{
            color: var(--color-primary);
            text-decoration-line: revert;
            font-size: var(--font-size-secondary);
        } 
        .login--container-main{
            margin: 20px 0;
            display: flex;
            height: auto;
            min-height: 420px;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-content: flex-start;
            .login--pregraft-default{
                color: var(--color-neutral-dark-II);
                font-size: var(--font-size-secondary);
            }
            .ms-Checkbox{
                margin: 10px 0;
            }
            >img{
                height: 70px;
            }
            p{
                font-size: var(--font-size-secondary);
                color: var(--color-neutral-dark-III);
                text-align: center;
            }
            .root-41{
                width: 95%;
                label{
                    font-weight: 400;
                    font-size: var(--font-size-secondary);
                }
            }
            .root-57{
                width: 100%;
                input{
                    height: 32px;
                }
            } 
            .recaptcha--container{
                width: 100%;
                height: 75px;
                margin: 20px 0;
                background: var(--color-neutral-base);
            }
            >button{
                width: 60%;
            }   
        }
        .login--container-footer{
            width: 100%;
            a{
                width: 100%;
                text-decoration-line: underline;
                font-size: var(--font-size-quarternary);
            }
        }
    }
}


 