.admin--container-base{
    margin: 0;
    width: 100%;
    display: inline-block;
    .admin--container-header{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: rgba($color: #000, $alpha: 0.5);
        .header--title{
            width: 260px;
            height: auto;
            min-height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            h2{
                margin: 0;
                font-weight: 400;
                color: var(--color-neutral-light-III);
            }
        }
        .header--profile{
            padding: 0 5px;
            width: calc(100% - 330px);
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-wrap: wrap;
            color: var(--color-neutral-light-III);
            .root-47, div{
                background:none;
            }
            // .root-40, .root-47, i{
            //     color: var(--color-neutral-light-III);
            //     font-size: var(--font-size-tertiary);
            //     &:hover{
            //         color: var(--color-neutral-dark-II);
            //     }
            // }
            .root-40{
                height: 2rem;
            }
            .header--icon{
                width: 100%;
                display: flex;
                cursor: pointer;
                margin: -8px 5px 0 0;
                justify-content: flex-end;
                a{
                    padding: 0 15px;
                    font-size: var(--font-size-pentanary);
                }
            }
            .ms-Button-menuIcon {
                background-color: transparent;
                color: #fff;
            }
        }
    }
    .admin--container-body{
        width: 100%;
        margin: 0;
        height: calc(100% - 120px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        background-color: var(--color-neutral-light-III);
        .admin--body-container{
            width: 96%;
            max-width: 1280px;
            min-height: calc(100% - 40px);
        }
    }
    .admin--container-footer{
        width: 100%;
        height: 60px;
        display: flex;
        color: var(--color-neutral-dark-II);
        justify-content: center;
        align-items: center;
        font-size: 20px;
        background-color: var(--color-neutral-light-II);
        // position: absolute;
        bottom: 0px;
        h6{
            font-weight: 400;
            margin-right: 15px;
        }
    }
}

.header--button-nav{
    margin: 0;
    width: 60px;
    height: auto;
    min-height: 60px;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    
    &:hover{
        border: none;
    }
}

$tamano:50px;

.session--client{
    .ms-Button--commandBar{
        // width: calc(100% - 2px);
        min-width: 151px;
        background:rgba(126,126,126);
        color: var(--color-neutral-light-III);
        button{
            height:'auto';
            background:rgba(126,126,126);
            color: var(--color-neutral-light-III);
        }
        i{
                
            background:rgba(27, 27, 27, 0.1);
            color: var(--color-neutral-light-III);
        }
        &:hover{
            height:auto;
            background:rgba(27, 27, 27, 0.1);
            color: var(--color-neutral-light-III);
        }
    }
}
.ms-Panel-commands{
    background-color: white;
}

.ml2-mfa{
    margin-left: 2rem;
}
.modal-mfa{

    .w10-mfa{
        width: 100%;
    }
    .form-w{
        width: 100%;
    }
    .w-support-input{
        width: 100%;
    }
    .ms-Dialog-main{
        max-width: 530px;
    }
}

.button-simple{
    min-width: 126px;
    min-height: 32px;
    margin-bottom: 5px;
    background-color: var(--color-primary);
    // position: absolute;
    bottom: 2%;
    // border: none;
    border-radius: 5px;
    color: var(--color-neutral-light-III);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        background-color: var(--color-light);
    }
}

.checkmarkIcon-162{width: 0px;}

@media only screen and (max-width : 700px) {
    .modal-mfa{
        .form-w{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .w-support-input{width: 80%;}
        .ml2-mfa{
            margin-left: 0px;
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .label-mfa{padding: 0px;}
        .w10-mfa{width: 80%;}
        .mg-resp{margin-top: 10px;}
        .ms-Dialog-main{max-width: 90% !important;}
    }
    .admin--container-footer{
        flex-direction: column;
    }
    .header--profile{
        background: transparent;
    }
}