.baseBodyUser{ 
    width: 100%;
    padding: 5px 0px;
    max-width: 580px;
    min-height: 510px;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    background-color: var(--color-neutral-light-III);
    z-index: 101;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px 0px;
    .bodyUser--container-header{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }
    .bodyUser--container-main{
        width: 100%;
        min-height: 400px;
        //margin: 15px 0;
        // margin-top: -60px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        z-index: 10;
        flex-direction: row;
        justify-content: space-evenly;
        background: var(--color-neutral-light-III);
        >img{
            width: auto;
            height: 70px;
            margin: 25px 0;
            //padding: 30px 0;
        }
        .ms-TextField{
            width: 90%;
            // margin-top: 10px;
            // margin-bottom: 20px;
            label{  
                font-weight: 400;
                color: var(--color-neutral-dark-II);
                font-size: var(--font-size-tertiary);
            }
            .fieldGroup-58, input{
                font-size: var(--font-size-quarternary);
                color: var(--color-neutral-dark-II);
                border:var(--color-neutral-light-I);
            }
            i{
                color: var(--color-neutral-base);
            }
        }
        .ms-Checkbox{
            width: 88%;
            margin: 15px 0 30px 0;
            .checkbox-68{
                border: 1px solid var(--color-primary);
                background: var(--color-primary);
            }
        }
    }
    .bodyUser--container-footer{
        width: 100%;
        margin: 15px;
        display: flex;
        // flex-wrap: wrap;
        // flex-direction: row;
        justify-content: space-between;
        a{
            font-size: var(--font-size-quarternary);
            color: var(--color-primary);
            // text-decoration-line:underline;
        }
    }
}
.just{
    display: flex;
    justify-content: space-evenly;
    min-height: 400px;
    align-items: center;
}
.center-items{
    display: flex;
    justify-content: center;
    align-items: center;
}
.button--default-pass{
    min-width: 126px;
    min-height: 32px;
    margin-bottom: 5px;
    // position: absolute;
    bottom: 2%;
    border: none;
    color: var(--color-neutral-light-III);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        border: none;
    }
}

.contSh{
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .baseShadowUser{ 
        width: 100%;
        height: 300px;
        padding: 5px 0px;
        max-width: 577px;
        min-height: 300px;
        margin: 15px auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: space-between;
        background-color: var(--color-neutral-light-III);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 40px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        position: absolute;
    }
}

.hipertext-end{
    cursor: pointer;
    &:hover{
        text-decoration-line: revert;
    }
}
.w52{width: 52%;}
.mgb{margin-bottom: 15px;}
.camp-radio{
    justify-content: space-around;
    display: flex;
    align-items: center;
    .radius-opt{
        input[type="radio"]{
            margin-top: 0px;
        }
        display: flex;
        align-items: center;
        accent-color: var(--color-primary);
        
    }

}