.modal--background{
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    flex-direction: row;
    justify-content: center;
    background: rgba($color: #000, $alpha: .3);
    .modal--container{
        margin: 30px 0;
        width: 100%;
        height: fit-content;
        max-width: 600px;
        border-radius: 5px;
        background: var(--color-neutral-light-III);
        .modal--header{
            width: 100%;
            padding: 15px 10px; 
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            box-sizing: border-box;
            justify-content: space-between;
            background: var(--color-neutral-light-I);
            a, h2{
                font-size: var(--font-size-primary);
            } 
            a{
                color: var(--color-neutral-dark-I);
            }
        }
        .modal--body{
            width: 100%;
            position: relative;
            background: var(--color-neutral-light-III);
            .modal--icon{
                top: 0;
                width: 100%;
                height: 4px;
                position: absolute;
                animation-duration: 2s;
                animation-name: animatemodal;
                a{
                    top: -20px;
                    left: calc(50% - 24px);
                    width: 48px;
                    height: 48px;
                    position:absolute;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: 1.2rem;
                }
            }
            .modal--icon-success{
                background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-success), rgba(0,0,0,.0));
                a{
                    background: var(--color-success);
                }
            }
            p.modal--icon-success{
                background: none;
                text-align: center;
                padding-top:70px;
            }
            .modal--icon-warning{
                background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-warning), rgba(0,0,0,.0));
                a{
                    background: var(--color-warning);
                }
            }
            p.modal--icon-warning{
                background: none;
                text-align: center;
                padding-top:70px;
            }
            .modal--icon-danger{
                background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-danger), rgba(0,0,0,.0));
                a{
                    background: var(--color-danger);
                }
            }
            .modal--icon-default{
                background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-primary), rgba(0,0,0,.0));
                a{
                    background: var(--color-primary);
                }
            }
            .modal--content-error{
                width: 100%;
                padding: 30px 0 15px 0;
                background-color: rgba($color: #c00, $alpha: .15);
                h6{
                    text-align: center;
                    font-size: var(--font-size-tertiary);
                }
            }
            .modal--content-text{
                width: 100%;
                padding: 10px 3rem;
                box-sizing: border-box;
                p{
                    text-align: justify;
                }
            }
            .modal--content-btns{
                margin: 1rem 0;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }
    }
}


@keyframes animatemodal {
    from{
       width: 0;
       left: 49.9%;
    }
    to{
        width: 100%;
        left: 0;
    }
}

@media only screen and (max-width : 900px) {
    .modal--body{
        min-width: auto;
    }
    .modal--content-btns{
        min-width: auto;
    }
}