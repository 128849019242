.container--icon-button{
    margin: 10px;
    padding: 5px;
    box-sizing: border-box;
    width: 162px;
    text-align: center;
    max-height: 144px;
    .icon--button-base{
        width: 110px;
        height: 114px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/img/ico-escudo-reposado.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        img{
            width: 75px;
            margin-bottom: 20px;
        }
        span{
            width: 80%;
            text-align: center;
            font-size: var(--font-size-tertiary);
            color: var(--color-neutral-light-III);
            margin-bottom: 20px;
        }
        &:hover{
            background-image: url('../../../assets/img/btn-escudo-hover.svg');
        }
    }

    .icon--button-base2{
        width: 85px;
        height: 87px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/img/ico-escudo-reposado.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        img{
            width: 75px;
            margin-bottom: 20px;
            position: absolute;
        }
        span{
            width: 80%;
            text-align: center;
            font-size: var(--font-size-tertiary);
            color: var(--color-neutral-light-III);
            margin-bottom: 20px;
            z-index: 1;
        }
    }
    .absol{
        position: absolute;
    }
}

.color-span{
    color: var(--color-primary);
    font-size: var(--font-size-quarternary);
}