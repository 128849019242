.container--pivot{
    width: 100%;
    .container--pivot-header{
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        height: auto;
        li{
            min-width: 100px;
            width: 33%;
            margin: 0%;
            .selected{
                color: var(--color-primary);
                border-bottom: 2px solid var(--color-primary);
                background: var(--color-neutral-light-III);
            }
        }
        button{
            width: 100%;
            height: auto;
            min-height: 38px;
            font-size: var(--font-size-pentanary);
            color: var(--color-neutral-base);
            background: var(--color-neutral-light-I);
            &:hover{
                color: var(--color-primary);
                border-bottom: 2px solid var(--color-primary);
                background: var(--color-neutral-light-III);
            }
        }
    }
    .container--pivot-content{
        display: flex;
        margin: 10px 0;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;  
        a{
            margin-top: 15px;
            margin-bottom: 30px;
        }
        img{
            height: 140px;
            width: 140px;
            background: var(--color-neutral-base);
        }
        .ms-TextField-wrapper {
            margin-bottom: 30px;
        }
    }
}