.ws{ width: 25%;}
.wm{ width: 42.5%;}
.wb{ width: 90%;}
.wa{width: auto;}

.w1{width: 10%;}
.w2{width: 20%;}
.w3{width: 30%;}
.w4{width: 40%;}
.w5{width: 50%;}
.w6{width: 60%;}
.w7{width: 70%;}
.w8{width: 80%;}
.w9{width: 90%;}
.w9_5{width: 95%;}
.w10{width: 100%;}
.w10_5{width: 105%;}

.wr1{width: 1rem;}
.wr2{width: 2rem;}
.wr3{width: 3rem;}
.wr4{width: 4rem;}
.wr5{width: 5rem;}
.wr6{width: 6rem;}
.wr7{width: 7rem;}
.wr8{width: 8rem;}
.wr9{width: 9rem;}
.wr10{width: 10rem;}

.h1{height: 1rem;}
.h2{height: 2rem;}
.h3{height: 3rem;}
.h4{height: 4rem;}
.h5{height: 5rem;}
.h6{height: 6rem;}
.h7{height: 7rem;}
.h8{height: 8rem;}
.h9{height: 9rem;}
.h10{height: 10rem;}
.h100{height: 100%}

.mg1{margin: 1px;}
.mg2{margin: 2px;}
.mg4{margin: 4px;}
.mg5{margin: 5px;}
.mg8{margin: 8px;}
.mg10{margin: 10px;}
.mg1-w{margin: 0 1px;}
.mg2-w{margin: 0 2px;}
.mg4-w{margin: 0 4px;}
.mg5-w{margin: 0 5px;}
.mg8-w{margin: 0 8px;}
.mg10-w{margin: 0 10px;}
.mg1-h{margin: 1px 0;}
.mg2-h{margin: 2px 0;}
.mg4-h{margin: 4px 0;}
.mg5-h{margin: 5px 0;}
.mg8-h{margin: 8px 0;}
.mg10-h{margin: 10px 0;}
.mg20-h{margin: 20px 0;}
.mg30-h{margin: 30px 0;}
.mg-hw{margin: 8px 4px;}
.container--flex{
    display: flex;
    flex-wrap: wrap;
}
.mg-a{margin: auto;}
.mla{margin-left: auto;}
.mra{margin-right: auto;}
.mta{margin-top: auto;}
.mba{margin-bottom: auto;}

.ml1{margin-left: 1rem;}
.ml2{margin-left: 2rem;}

.pl2{padding-left: 2rem;}
.pl3{padding-left: 3rem;}
.pl4{padding-left: 4rem;}
.pl5{padding-left: 5rem;}

.pg1{padding: 1px;}
.pg2{padding: 2px;}
.pg4{padding: 4px;}
.pg5{padding: 5px;}
.pg8{padding: 8px;}
.pg10{padding: 10px;}

.pgt10{padding-top: 10px;}

.c--flex-f1{flex:1;}
.c--flex-f2{flex:2;}
.c--flex-f3{flex:3;}
.dsp-flex{display: flex;}
.c--flex-dr{flex-direction: row;}
.c--flex-dc{flex-direction: column;}

.c--flex-wc{justify-content: center;}
.c--flex-we{justify-content: flex-end;}
.c--flex-wsb{justify-content: space-between;}
.c--flex-wse{justify-content: space-evenly;}
.c--flex-wsa{justify-content: space-around;}
.c--flex-hc{align-items: center;}
.c--flex-he{align-items: flex-end;}
.c--flex-hsb{align-content: space-between;}
.c--flex-hsa{align-content: space-around;}

.bkgn--light-III{background: var(--color-neutral-light-III);}
.bkgn--light-II{background: var(--color-neutral-light-II);}
.bkgn--light-I{background: var(--color-neutral-light-I);}
.bkgn--base{background: var(--color-neutral-base);}
.bkgn--dark-I{background: var(--color-neutral-dark-I);}
.bkgn--dark-II{background: var(--color-neutral-dark-II);}
.bkgn--dark-III{background: var(--color-neutral-dark-III);}
.bkgn--smlight{background: var(--color-semilight);}
.bkgn--light{background: var(--color-light);}
.bkgn--primary{background: var(--color-primary);}
.bkgn--dark{background: var(--color-dark);}
.bkgn--smdark{background: var(--color-semidark);}

.fr{float: right;}
.fl{float: left;}
.ha{height: auto;}

.div-foot-rel{
    position: fixed;
    bottom: 0px;
}

.div-foot-in{
    position: fixed;
    bottom: 0px;
}


.bdr--light-III{border: 1px solid var(--color-neutral-light-III);}
.bdr--light-II{border: 1px solid var(--color-neutral-light-II);}
.bdr--light-I{border: 1px solid var(--color-neutral-light-I);}
.bdr--base{border: 1px solid var(--color-neutral-base);}
.bdr--dark-I{border: 1px solid var(--color-neutral-dark-I);}
.bdr--dark-II{border: 1px solid var(--color-neutral-dark-II);}
.bdr--dark-III{border: 1px solid var(--color-neutral-dark-III);}
.bdr--smlight{border: 1px solid var(--color-primary);}
.bdr--light{border: 1px solid var(--color-secondary);}
.bdr--primary{border: 1px solid var(--color-tertiary);}
.bdr--dark{border: 1px solid var(--color-quarternary);}
.bdr--smdark{border: 1px solid var(--color-pentanary);}

.clr--light-III{color: var(--color-neutral-light-III);}
.clr--light-II{color: var(--color-neutral-light-II);}
.clr--light-I{color: var(--color-neutral-light-I);}
.clr--base{color: var(--color-neutral-base);}
.clr--dark-I{color: var(--color-neutral-dark-I);}
.clr--dark-II{color: var(--color-neutral-dark-II);}
.clr--dark-III{color: var(--color-neutral-dark-III);}
.clr--smlight{color: var(--color-semilight);}
.clr--light{color: var(--color-light);}
.clr--primary{color: var(--color-primary);}
.clr--dark{color: var(--color-dark);}
.clr--smdark{color: var(--color-semidark);}

.txt--prymary{font-size: var(--font-size-primary);}
.txt--secondary{font-size: var(--font-size-secondary);}
.txt--tertiary{font-size: var(--font-size-tertiary);}
.txt--quarternary{font-size: var(--font-size-quarternary);}
.txt--pentanary{font-size: var(--font-size-pentanary);}

.txt--aling-l{text-align: left;}
.txt--aling-r{text-align: right;}
.txt--aling-c{text-align: center;}
.txt--aling-j{text-align: justify;}

.hipertext{
    width: 100%;
    cursor: pointer;
    &:hover{
        text-decoration-line: revert;
    }
}
.hipertext--primary{
    text-align: center;
    color: var(--color-primary);
    font-size: var(--font-size-secondary);
}
.hipertext--second{
    text-align: center;
    color: var(--color-neutral-dark-I);
    font-size: var(--font-size-quarternary);
}

.logoPath{
    width: 100px;
    height: 100px;
}

.hide {
    display: none;
}

.button--default{
    min-width: 126px;
    min-height: 32px;
    margin-bottom: 5px;
    // position: absolute;
    bottom: 2%;
    border: none;
    color: var(--color-neutral-light-III);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        border: none;
    }
}
.button--disable{
    background-color: var(--color-neutral-dark-I);
    color: var(--color-neutral-base);
    transition: all 0.3s;
    // &:hover{
    //     transition: all 0.3s;
    //     background-color: var(--color-neutral-dark-II);
    //     color: var(--color-neutral-base);
    // }
}
.button--primary{
    background-color: var(--color-primary);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        background-color: var(--color-light);
    }
}
.button--second{
    color: var(--color-semidark);
    background-color: var(--color-neutral-light-I);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        color: var(--color-primary);
        background-color: var(--color-neutral-light-II);
    }
}

.button--border{
    border-radius: 5px;
    background: none;
    color: var(--color-semidark);
    border: 1px solid var(--color-semidark);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        color: var(--color-neutral-light-III);
        background-color: var(--color-semidark);
    }
}

.button--success{
    background-color: var(--color-success);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        background-color: var(--color-success);
    }
}
.button--danger{
    background-color: var(--color-danger);
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        background-color: var(--color-danger);
    }
}

.button--float{
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 38px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-dark-II);
    font-size: var(--font-size-secondary);
    background: var(--color-neutral-light-II);
    box-shadow: 0px 6px 6px 2px var(--color-neutral-base);
    transition: all 0.3s;
    &:hover{
        background:var(--color-neutral-light-III);
        transition: all 0.3s;
    }
}

.scroll{
    
    height: auto;
    width: 38rem;
    overflow-y:scroll;
    overflow-x:hidden;
    scrollbar-width: none;

    .ms-Label{
        color: var(--unnamed-color-4b4b4b);
        text-align: left;
        font: var(--font-primary);
        letter-spacing: 0px;
        color: #4B4B4B;
        margin-top: 20px;
    }
}

.scrollUserFinal{
    
    height: auto;
    // width: 96%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-top: -25px;
    margin-left: 5%;

    .ms-Label{
        color: var(--unnamed-color-4b4b4b);
        text-align: left;
        font: var(--font-primary);
        letter-spacing: 0px;
        color: #4B4B4B;
        margin-top: 20px;
    }

    
}
.scrollUserAdmin{
    
    height: auto;
    // width: 96%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-top: -25px;
    // margin-left: 5%;
    padding: 0 5% 0 2%;

    .ms-Label{
        color: var(--unnamed-color-4b4b4b);
        text-align: left;
        font: var(--font-primary);
        letter-spacing: 0px;
        color: #4B4B4B;
        margin-top: 20px;
    }

    .w45{
        width: 45%;
        margin-left: 25px;
    }
}
.icon-color-escudo{
    margin-bottom: 15px;
    width: 55px;
    
}
.icon-color-escudo-hov{
    margin-bottom: 15px;
    width: 40px;
    
}

.container-access{
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.label-card{
    align-items: center;
    background-color: #0d3273;
    display: flex;
    height: auto;
    justify-content: center;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
}

.label-card label{
    color: white;
    margin: auto;
    padding: 15px;
    font-family: var(--font-primary);
}

.input-filter{
    height: 28px;
}
.message-error{
    width: 100%;
    height: 25px;
    background: #F5D4D4;
    padding: 5px 0px 0px 0px ;
    margin-top: -16px;
    border-radius: 5px;
    position: absolute;
    .paragraf-error{
        margin: 0px 10px;
        font-size: var(--font-size-quarternary);
         color: var(--color-danger);
         font-weight: bolder;
    }
}
.modal-terms{
    .ms-Modal-scrollableContent{
        max-height: 100%;
    }
    .ms-Dialog-main{
        width: 40%;
        height: 100%;
        margin: 0px 20px 10px 0px;
    }
    .ms-Panel-main {
        width: 30%;
        margin: 0px 673px 10px 0px;
    }
    .ms-Overlay--dark {
        background-color: transparent;
    }
    .header-modal{
        flex: 1 1 auto;
        color: var(--color-neutral-dark-II);
        // background-color:  #eaeaea;
        display: flex;
        align-items:  center;
        padding: 8px 12px 10px 20px;
       font-size: 20px;
    }
    .ms-Panel-commands{
    background-color: white;
   }
}
    li, ul{
        .listStyle{
        list-style: initial;
    }
}

// .label-mfa{

// }

.component-card{
    .card-num{
        height: 100px;
        background: #f4f4f4;
        padding: 10px;
        .num-card{
            color: white;
            position: absolute;
            right: 10px;
            bottom: 15px;
            min-width: 90px;
            width: auto;
        }
    }
}
.smallS{
    padding: 10px;
}
@media only screen and (max-height: 670px) {
    .smallS{
            margin-bottom: 30px; 
    }
}
@media only screen and (max-width : 700px) {
    .w10-resp{width: 100% !important;}
    .w9-resp{width: 90% !important;}
    .w8-resp{width: 80% !important;}
    .w7-resp{width: 70% !important;}
    .w6-resp{width: 60% !important;}
    .w5-resp{width: 50% !important;}
    .dsp-flex{flex-flow: column;}
    .dsp-none{display: none !important;}
    .mrg-top-resp1{margin-top: 10px;}
    .pdg1-w{padding: 0px 10px;}
    .card-num{
        display: flex;
        justify-content: center;
        align-items: center;
        .num-card {position: inherit !important;}
    }
}
