.modal--body{
    width: 100%;
    position: relative;
    // background: var(--color-neutral-light-III);
    max-width: 600px;
} 
    .modal--icon{
        // top: -15px;
        width: 100%;
        height: 4px;
        position: absolute;
        animation-duration: 2s;
        animation-name: animatemodal;
        a{
            top: -20px;
            left: calc(50% - 24px);
            width: 48px;
            height: 48px;
            position:absolute;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 1.2rem;
        }
    }
    .modal--icon-success{
        background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-success), rgba(0,0,0,.0));
        a{
            background: var(--color-success);
        }
    }
    p.modal--icon-success{
        background: none;
        text-align: center;
        padding-top:70px;
    }
    .modal--icon-warning{
        background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-warning), rgba(0,0,0,.0));
        a{
            background: var(--color-warning);
        }
    }
    p.modal--icon-warning{
        background: none;
        text-align: center;
        padding-top:70px;
    }
    .modal--icon-danger{
        background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-danger), rgba(0,0,0,.0));
        a{
            background: var(--color-danger);
        }
    }
    .modal--icon-default{
        background: linear-gradient(90deg, rgba(0,0,0,.0), var(--color-primary), rgba(0,0,0,.0));
        a{
            background: var(--color-primary);
        }
    }
    .modal--content-error{
        width: 100%;
        padding: 30px 0 15px 0;
        background-color: rgba($color: #c00, $alpha: .15);
        h6{
            text-align: center;
            font-size: var(--font-size-tertiary);
        }
    }
    .modal--content-text{
        width: 100%;
        padding: 10px 3rem;
        box-sizing: border-box;
        p{
            text-align: justify;
        }
    }
    .modal--content-error2{
        width: 100%;
        padding: 30px 0 15px 0;
        h6{
            text-align: center;
            font-size: var(--font-size-tertiary);
        }
    }
    
.content-modal{
    display: flex;
    flex-flow: column;
    height: 90%;
    .modal--content-btns{
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
    }
}
.center-text{
    background: none;
    text-align: center;
    padding-top:70px;
}
.pd{
    padding: 1rem;
}

.d--flex{
    display: flex;
    justify-content: space-between;
}

.br{
    border-radius: 4px;
    border: 1px solid var(--color-primary);
}

.ms-Dialog-main{
    border-radius: 6px;
}
@media only screen and (max-width : 900px) {
    .modal--body{
        height: inherit;
        min-width: auto;
    }
    .modal--content-btns{
        min-width: auto;
    }
}

.ms-Button{
    i{
    color: var(--color-primary);
    }
}
@media only screen and (max-width : 700px) {
    .flex-fc{flex-flow: column;}
    .mrg-b{margin-bottom: 15px;}
    .btn-h-resp{height: 35px;}

    .ms-Modal-scrollableContent{
        height: 90vh;
    }
    .button--default-resp-h{
        min-height: 45px !important;
    }
    .center-text{
        padding-top:100px;
    }
    .d--flex{
        display: block;
    }
}