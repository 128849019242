.list--container-body{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .list--body-header{
        width: calc(100% - 20px);
        min-height: 48px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid var(--color-primary);
        .list--body-subintems{
            flex: 1;
            min-height: 28px;
            padding: 0 10px 0 10px;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            cursor: pointer;
            font-weight: 700;
            font-size: var(--font-size-secondary);
            transition: all 0.3s;
            color: var(--color-primary);
            i{
                margin: 0 5px;
                font-weight: 900;
                color: var(--color-neutral-base);
            }
            p{
                margin: 0;
            }
            &:hover{
                font-weight: 900;
                transition: all 0.3s;
            }
            &:last-child{
                width: 12%;
                justify-content: center;
            }
        }
        
    }

    .list--body-headerI{
        @extend .list--body-header;
        padding: 10px 10px 10px 50px;
        justify-content: flex-start;
        .list--body-subintems{
            @extend .list--body-subintems;
            width: 20%;
            &:last-child{
                justify-content: flex-start;
            }
        }
    }
    .list--body-content{
        width: calc(100% - 20px);
        border-top: 1px solid var(--color-primary);
        border-bottom: 1px solid var(--color-primary);
        ul, li{
            list-style: none;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .list--body-item{
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid var(--color-neutral-base);
            li{
                flex: 1;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                padding: 5px 30px;
                a{
                    cursor: pointer;
                    padding: 2px 6px; 
                    font-weight: 600;
                    border-radius: 60px;
                    color: var(--color-neutral-light-III);
                    background-color: var(--color-danger);
                }
                &:last-child{
                    width: 12%;
                    justify-content: center;
                }     
            }
            &:hover{
                background-color: var(--color-neutral-light-II);
                border: none;
            }
            &:last-child{
                border: none;
            }
        }  

        .list--item-select{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            box-sizing: border-box;
            align-items: center;
            // margin: 10px 0;
            min-height: 50px;
            border-bottom: 1px solid var(--color-neutral-base);
            label{
                padding: 15px 10px;
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                margin: 0;
                >li{
                    flex: 1;
                    padding: 0 10px 0 28px;
                }
                &:hover{
                    background-color: var(--color-neutral-light-II);
                }
            }

            input[type="checkbox"]{
                position: relative;
                cursor: pointer;
                border: none;
                margin-right: 2em;
                &:before{
                    content: "";      
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    top: -2px;
                    left: -4px;
                    border-radius: 50px;
                    border: 2px solid var(--color-neutral-light-I);
                    background-color:var(--color-neutral-light-II);  
                }

                &:checked:before{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 50px;
                    top: -2px;
                    left: -4px;
                    background-color:var(--color-primary);
                }

                &:checked:after{
                    content: "";
                    display: block;
                    width: 4px;
                    height: 8px;
                    border: solid white;
                    border-radius: 50px;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 2px;
                    left: 3px;


                }
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }
    .list--body-footer{
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        box-sizing: border-box;
        .list--body-subitem{
            width: 50%;
            padding: 10px;
            display: flex;
            box-sizing: border-box;
            ul{
                list-style: none;
                display: flex;
                li{
                    list-style: none;
                    cursor: pointer;
                    margin: 0 5px;
                    a{
                        font-size: var(--font-size-tertiary);
                        color: var(--color-primary);
                        &:hover{
                            font-weight: 700;
                        }
                    }
                }
            }
            &:last-child{
                justify-content: flex-end;
            }
        }
    }
}