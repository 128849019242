.rat{
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ffffff;
    animation-name: fondo;
    animation-duration: 3s;
    display: flex;
}

.padd{
    //margin-top: 12%;
    //padding: 3rem;
    //background-color: aqua;
    width: 45%;
}

.padd2{
    //margin-top: 12%;
    padding: 3rem;
    //background-color: orange;
    width: 55%;
}

.tar{
    position: absolute;
    padding: 3rem;
}


    i.email{
        font-size: 36px;
        color: #0C3273;
        animation-name: animate;
        animation-duration: 3s;
    }


.alert{
    margin-top: 19%;
    animation-name: animate;
    animation-duration: 3s;
}
.paragraf{
    color: #0C3273; 
    padding:0.6em 0 4em 0;
    line-height: 1.5;
    animation-name: animate;
    animation-duration: 3s;
}

#linetext{
    float: left;
}

.linetext{
    color: #0C3273; 
    padding:0.6em 0 4em 0;
    line-height: 1.5;
    animation-name: animate;
    animation-duration: 3s;
    margin-top: -2px;
}
.dark{
    margin-top: 14%;
    margin-left: 4%;
    position: absolute;
    opacity: 1;
    z-index: 3;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-duration: 3s;
    animation-name: animate;
}
.light{
    margin-top: 14%;
    margin-left: 4%;
    position: absolute;
    opacity: 1;
    z-index: 3;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: animate;
    animation-duration: 3s;

}
.dark:hover{
    opacity: 0;
}

@keyframes fondo{
    from {background-color: #08204A;}
      to {background-color: #ffffff;}
}

@keyframes animate{
    from {opacity: 0;}
      to {opacity: 1;}
}