.panel--container{
    width: 295px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.2);
    background-color: var(--color-neutral-light-III);
    animation-duration: 0.3s;
    animation-name: showpanel;
    overflow: scroll;
    .panel--header{
        width: 100%;
        height: 60px;
        .panel--header-title{
            margin: 0;
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--color-neutral-light-III);
            background-color: var(--color-primary); 
            i{
                margin: 0 12px;
                font-weight: 200;
                transform: scale(2.4,1);
            }
            h3{
                margin: 0 30px;
                font-size: var(--font-size-primary);
                font-weight: 200;
                transform: scale(1.1,1);
            }
        }
        .panel--header-close{
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background-color: var(--color-neutral-light-II);
            a{
                margin: 0 10px;
                font-size: 30px;
                cursor: pointer;
                transition: all .2s;
                &:hover{
                    font-weight: 800;
                    transition: all .2s;
                }
            }
        }
    }
}

.linkText-196{
    color: black;
}

@keyframes showpanel {
    from{
        left: -300px;
    }
    to{
        left: 0px;
    }
}

@keyframes hiddenpanel {
    from{
        left: 0px;
    }
    to{
        left: -300px;
    }
}
.panel{
    .commands-170 {
        padding-top: 0;
    }
    .content-176 {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}
.panel--header{
    width: 100%;
    height: 60px;
    .panel--header-title{
        margin: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--color-neutral-light-III);
        background-color: var(--color-primary); 
        i{
            margin: 0 12px;
            font-weight: 200;
            transform: scale(2.4,1);
            cursor: pointer;
            user-select: none;
        }
        h3{
            margin: 0 30px;
            font-size: var(--font-size-primary);
            font-weight: 200;
            transform: scale(1.1,1);
        }
    }
    .panel--header-close{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: var(--color-neutral-light-II);
        a{
            margin: 0 10px;
            font-size: 30px;
            cursor: pointer;
            transition: all .2s;
            &:hover{
                font-weight: 800;
                transition: all .2s;
            }
        }
    }
}