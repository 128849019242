.form--container-panel{
    width: 100%;
    
    .form--container-title{
        width: 100%;
        padding: 15px 0;
        box-sizing: border-box;
        h4{
            font-size: var(--font-size-primary);
            font-weight: 600;
            color: var(--color-primary);
        }
    }

    .form--container-subtitle{
        width: 100%;
        padding: 0 0 12px 0;
        box-sizing: border-box;
        h4{
            font-size: var(--font-size-primary);
            font-weight: 600;
            color: var(--color-primary);
        }
        h5{
            font-size: var(--font-size-secondary);
            font-weight: 600;
            color: var(--color-primary);
        }
    .ms-Label{
        font-family: var(--font-size-tertiary);
        font-size: var(--font-size-tertiary);
        font-weight: normal;
    }
}
    .form--container-body{
        width: 100%;
        min-height: 400px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        box-sizing: border-box;
        justify-content: space-between;
        background-color: var(--color-neutral-light-II);
        .form--content-select{
            min-width: 250px;
            //height: 10px;
            box-sizing: border-box;
            label{
                width: 100%;
                font-weight: 400;
                padding: 6px 0;
                font-size: var(--font-size-secondary);
            }
            .gg{
                font-size: var(--font-size-quarternary);
            }
            select{
                border: 1px solid var(--color-neutral-light-II);
                color: hsl(0, 0%, 80%);
                border-color: hsl(0, 0%, 80%);
                border-radius: 4px;
                border-style: solid;
                border-width: 1px;
                padding: 5px;
                min-height: 38px;

                option{
                    color: black;
                }
            }
        }
        .form--content-select{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            select{
                width: 100%;
                height: 32px;
            }
        }
        
        
    }
    .form--container-body-small{
        padding: 45px 18px;
    }
}

.ms-Dropdown-title{
    border: 1px solid var(--color-neutral-light-II);
                color: hsl(0, 0%, 80%);
                border-color: hsl(0, 0%, 80%);
                border-radius: 4px;
                border-style: solid;
                border-width: 1px;
                //padding: 5px;
                min-height: 25px;
}


.form--content-radio{
    h6{
        font-weight: 400;
        color: var(--color-primary);
        font-size: var(--font-size-secondary);
    }
    label{
        width: 6rem;
        height: 6rem;
        position: relative;
        background: var(--color-neutral-light-III);
        p, span{
            margin-bottom: 0;
            text-align: center;
        }
        span{
            color: var(--color-neutral-base);
            font-size: var(--font-size-pentanary);
        }
        input[type="radio"]{
            top: 0.125rem;
            right: 0.125rem;
            position: absolute;
        }
    }
}

div.react-colorful__saturation, div.react-colorful__last-control{
    border-radius: 0px;
}

div.react-colorful__alpha, div.react-colorful__hue{
    margin: 4px 0;
    height: 19px;
}

div.react-colorful__pointer{
    width: 21px;
    height: 21px;
}



.color_boton{
    color: white; 
}

.color_div{
    margin: 10px;
    width: 90px;
    border-radius: 9px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.preview{
    font-size: 18px;
}

.previewfond{
    width: 40%;
    margin-right: 10px;
}

.inp{
    width: 33px;
}
.inpe{
    width: 58px;
}

.pForm{
    float: left;
    width: 45px;
  }

  .pForm1{
    float: left;
    width: 70px;
  }
.preview1{
    font-size: 12px;
}
.largo{
    width: 45%;
}
.prev{

 padding: 20px;

}

#lado{
    float: left;
    width: 40%;
}
.pala{
    cursor: pointer;
}
.actualiza{
    float: right;
    top: 0px;
    width: 29%;
    padding: 5px;
    height: 30px;
    background: none;
    text-align: center;
    background: var(--color-semilight);
    border: 1px solid var(--color-primary);
    margin-right: 1px;
    color: white;
}

.form--content-selectSearch{
    min-width: 250px;
    //height: 10px;
    box-sizing: border-box;
    label{
        display: flex;
        margin-bottom: 10px;
        margin-top: 5px;
        width: 100%;
        font-weight: 400;
        padding: 6px 0;
        font-size: var(--font-size-secondary);
    }
    select{
        border: 1px solid var(--color-neutral-light-II);
    }
}
.radio-capt{
    .ms-ChoiceField-innerField{
        height: 50px;
        width: auto;
        position: relative;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        // background: red;
    }
    
}
.form--content-selectSearch{
    
    flex-wrap: wrap;
    align-items: center;
}
.labelStyle{
    font-weight: 400;
    font-size: var(--font-size-secondary);
    
}
.class-target{
    .ms-Icon{
        font-size: 25px;
        height: 27px;
        width: 27px;
        margin: 0 23px;
        color: white;
        position: absolute;
        bottom: -17px;
        left: 30%;
        border-radius: 50%;
        padding: 3%;
        text-align: center;
    }
}
.topCheck{
    margin: 10px 0 10px 0;
}
.errorMessage-184{
    color: var(--color-danger);
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    background: rgb(245, 212, 212);
    border-radius: 4px;
    margin-top: 2px;
}
.ms-Stack{
    .ms-TextField-fieldGroup{
            margin: 0;
            padding: 0px;
            width: 100%;
            height: 32px;
            box-sizing: border-box;
            transition: all 0.3s;
            color: var(--color-neutral-dark-II);
            border: 1px solid var(--color-neutral-light-I);
            // box-shadow: 0px 0px 16px #00000029;
            &:hover{
                transition: all 0.1s;
                border: 1px solid var(--color-neutral-base);
            }
            
            &::after{
                border: none;
            }
            .ms-TextField-field{
                border: none;
            }
    }
    .ms-TextField-field{
        &:focus{
            outline: none;
            border: none;
            transition: all 0.3s;
            box-shadow: 0px 0px 10px 3px var(--color-neutral-light-I);

        }
    }

    
}
.ms-TextField-errorMessage{
    color: var(--color-danger);
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 400;
    padding: 7px;
    background: rgb(245, 212, 212);
    border-radius: 4px;
    margin-top: 2px;
}

.radius{
    background: ghostwhite;
    width: 25vw;
    float: left;
}

@media screen and (max-width:1920px) and (min-width:801px) {
    .select2{
        //background: blue;
        width: 48%;
    }
    .pad-larg{
        padding-top: 5%;
    }
    .pad-short{
        padding-top: 3%;
    }
}


@media screen and (max-width:800px) and (min-width:400px) {
    .select2{
        //background: yellow;
        width: 100%;
    }

    .pad-larg{
        padding-top: 5%;
    }
    .pad-short{
        padding-top: 3%;
    }
}
.colorNorm{
    .ms-Label{
        //font-size: var(--font-size-secondary);
        font-weight: 600;
    }
}
// css-112 ms-Dropdown-items dropdownItems-180
// .multi-select{
    .ms-FocusZone, .css-112, .ms-Dropdown-items, .dropdownItems-180{
    background: #fff;
    } 
    .ms-Checkbox-label{
        background: #fff;
    }
// }
.pdg-resp{padding: 2rem;}

.ms-Dropdown-title{
    padding: 0px 28px 0px 8px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-neutral-light-I);
    border-radius: 5px;
}
.mr-slc{
    margin-right: 10rem;
}

.mt-tgl{
    margin-top: 8px;
}
.bkg-green{background: #0f7c11;}
.bkg-orange{background: #f37907;}
.bkg-yellow{background: #ffb902}
.bkg-red{background: #cb0102;}
.icon-position{
   position: relative;
}
@media only screen and (max-width : 700px) {
    .pdg-resp{padding: 20px;}
    .ms-DocumentCard{
        min-width: auto;
        i{margin: 0px 15px;}
        label{text-align: center;}
    }

    .class-target{
        .ms-Icon{
            font-size: 21px;
            height: auto;
            width: auto;
            margin: 0 12px;
            color: white;
            position: absolute;
            bottom: -17px;
            left: 30%;
            border-radius: 50%;
            padding: 6%;
            text-align: center;
        }
    }
}

