h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: 400;
}

// li, ul{
//     list-style: none;
//     padding: 0;
// }

a, i{
    font-style: normal;
    text-decoration: none;
}
a{
    cursor: pointer;
}

button{
    border: none;  
}

input[type="file"]{
    width: 100%;
    outline: none;
    padding: 6px 3px;
    box-sizing: border-box;
    position: relative;
    color: var(--color-neutral-dark-I);
    font-size: var(--font-size-quarternary);
    border: 1px solid var(--color-neutral-base);
    background: var(--color-neutral-light-III);
}
input[type="file"]::after{
    top: 0px;
    right: 0;
    width: 25%;
    padding: 5px;
    height: 32px;
    outline: none;
    background: none;
    text-align: center;
    position: absolute;
    content: 'Browser';
    color: var(--color-primary);
    height: -webkit-fill-available;
    background: var(--color-neutral-light-III);
    border: 1px solid var(--color-primary);
}
::-webkit-file-upload-button{
    display: none;
}

input[type="color"]{
    border: none;
}
select:not(.no-style){
    min-height: 2rem;
    min-width: 7rem;
    outline: none;
    height: 2rem;
    line-height: 2rem
}


::-webkit-scrollbar {
    width: 3px;
    height: 6px;  
}

::-webkit-scrollbar:hover {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    opacity: 0;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--color-semilight);
}

::-webkit-scrollbar-thumb:hover {
    width: 4px;
    border-radius: 4px;
    background-color: var(--color-semilight);
}

.select-filter{
    min-height: 2rem;
    outline: none;
    height: 2rem;
    line-height: 2rem
}