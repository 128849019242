.list--container-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .list--subcontainer-header{
        // width: calc(50% - 20px);
        margin: 10px;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .history{
            i{
                // display: 10px 0px 0px 0px;
                font-size: 16px;
                margin: 0 5px 0 0;
            }
            .arrow{
                font-size: 12px;
            }
          
        }
        &:nth-child(2n){
            justify-content: flex-end;
            button{
                border: 1px solid var(--color-primary);
                color: var(--color-primary);
            }
        }
        &:nth-child(3), &:nth-child(4){
            align-items: baseline;
            p{
                margin: 0;
                color: var(--color-neutral-dark-II);
            }
        }
        h1{
            // width: 100%;
            font-weight: 600;
            color: var(--color-neutral-dark-III);
        }
        h6{
            font-weight: 400;
            text-align: end;
            width: 240px;
            // margin: 0 0 14px 50%;
            color: var(--color-neutral-base);
        }
        p{
            width: 100%;
            margin: 0;
            // margin-bottom: 5px;
            color: var(--color-neutral-dark-II);
        }
        .FinalColor{
            color: var(--color-primary);
        }
        .w-title{
            width: 60%;
        }
    }
}
.w-inputs-filter{width: 160px;}
@media only screen and (max-width : 700px) {
    h6{text-align: initial !important;}
    .end-visit{margin: 10px 0px;}
    .mrg-bot-resp{margin-bottom: 20px;}
    .sel-resp{width: 80px !important;}
    .w-title{width: 100% !important;}
    .initial-inbox{
        button{align-self: start !important;}
    }
    .list--subcontainer-header:nth-child(2n) {
        justify-content: center !important;
    }
}
