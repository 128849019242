.noticealert--container{
    width: calc(100% - 20px);
    padding: 10px 10px;
    align-items: center;
    justify-content: space-between;
    p, a{
        margin: 0;
        font-weight: 700;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-pentanary);
        span{
            font-weight: 600;
            color: #F37807;
        }
    }
    .stron-weight{
        display: flex;
        i{
        font-weight: 800;
        }
    }
    
}

.noticealert--container--select{
    width: auto;
    margin: 8px 0px 2px 0px;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    p, a{

        margin: 0;
        font-weight: 300;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-quarternary);
        span{
            font-weight: 400;
            color: #F37807;
        }
    }
    
}

.warning{
    background: var(--color-warning-fond);
}

.noticealert--container--criticalIndicator{
    width: calc(100% - 20px);
    padding: 10px 10px;
    align-items: center;
    justify-content: space-between;
    p, a{
        margin: 0;
        font-weight: 700;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-pentanary);
        span{
            font-weight: 600;
            color: #CC0000;
        }
    }
    .w97{
        width: 96%;
    }
    .stron-weight{
        display: flex;
        i{
        font-weight: 800;
        }
    }
    
}

.noticealert--container--select--criticalIndicator{
    width: auto;
    margin: 8px 0px 2px 0px;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    p, a{

        margin: 0;
        font-weight: 300;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-quarternary);
        span{
            font-weight: 400;
            color: #CC0000;
        }
    }
    
}

.criticalIndicatorStyle{
    background: #F0CECE;
}

.noticealert--container--mildIndicator{
    width: calc(100% - 20px);
    padding: 10px 10px;
    align-items: center;
    justify-content: space-between;
    p, a{
        margin: 0;
        font-weight: 700;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-pentanary);
        span{
            font-weight: 600;
            color: #0C5A0C;
        }
    }
    .w97{
        width: 96%;
    }
    .stron-weight{
        display: flex;
        i{
        font-weight: 800;
        }
    }
    
}

.noticealert--container--select--mildIndicator{
    width: auto;
    margin: 8px 0px 2px 0px;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    p, a{

        margin: 0;
        font-weight: 300;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-quarternary);
        span{
            font-weight: 400;
            color: #0C5A0C;
        }
    }
    
}

.mildIndicatorStyle{
    background: #DEEDDE;
}

.parraf{
    width: 280px;
    line-height: 28px;
}

