.container--buttonFloat{
    width: 100%;
    height: 38px;
    //margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    flex-direction: center;
    align-items: center;
    transition: all 0.3s;
    background: var(--color-neutral-light-II);
    box-shadow: 0px 0px 8px 1px var(--color-neutral-base);
    a{
        margin: 0;
        width: 24px;
        margin: 5px 10px; 
        text-align: center; 
        transition: all 0.3s;
        &:hover{
            transition: all 0.3s;
            border: none;
            // background: var(--color-neutral-dark-I);
        } 
    }
    p{
        margin: 0;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-tertiary);
    }
    
}

.btnFloat-header{
    justify-content: center;
    >a{
        // margin: 0 8px;
        // padding: 0 5px;
        transition: all 0.3s;
        display: flex;
        font-size: var(--font-size-secondary);
        // &:hover{
            // transition: all 0.3s;
            background: var(--color-neutral-light-I);
            padding: 3px;
        // }
    }
}

.btnFloat-footer{
    justify-content: flex-start;
    &:hover{
        box-shadow: none;
        transition: all 0.3s;
        // background: var(--color-neutral-light-II);
    }
    a{
        &:hover{
            transition: all 0.3s;
            border: none;
            background: none;
        } 
    }
}

.mrgin-top{margin-top: -1px;}

.flt-r{
    width: 100%;
    height: 38px;
    //margin: 10px 0;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;
    background: var(--color-neutral-light-II);
    box-shadow: 0px 0px 8px 1px var(--color-neutral-base);
    a{
        margin: 0;
        width: 24px;
        margin: 5px 10px; 
        text-align: center; 
        transition: all 0.3s;
        &:hover{
            transition: all 0.3s;
            border: none;
            background: var(--color-neutral-dark-I);
        } 
    }
    .izq-p{
        margin: 0;
        color: var(--color-neutral-dark-II);
        font-size: var(--font-size-tertiary);
    }
    .der-p{
        margin: 0;
        color: var(--color-neutral-dark-I);
        font-size: var(--font-size-quarternary);
    }
}

