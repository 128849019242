.loading--container{
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .login--container-icon{
        height: 110px;
        padding: 10px;
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count:infinite;
        img{
            height: 110px;
        }
        .img1{
            height: 110px;
        }
    }
    .login--container-icon2{
        position: absolute;
        height: 110px;
        padding: 10px;
        animation-name: loading2;
        animation-duration: 1.5s;
        animation-iteration-count:infinite;
        img{
            height: 110px;
        }
    }
}

@keyframes loading {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}

@keyframes loading2 {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
