.selectFile--container{
    width: 90%;
    select{
        border: none;
        width: 100%;
        height: 32px;
        font-size: var(--font-size-tertiary);
        color: var(--color-neutral-dark-II);
        border: 1px solid var(--color-neutral-light-I);
        transition: all 0.3s;
        margin-bottom: 5px;
        &:hover{
            transition: all 0.3s;
            border: 2px solid var(--color-neutral-base);
        }
        &:focus{
            outline: none;
            border: none;
            box-shadow: 0px 0px 6px 2px var(--color-neutral-light-I);
        }
        .active{
            background: var(--color-neutral-light-II);
        }
    }
}