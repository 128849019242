.headerForm--container{
    width: 90%;
    margin: 20px 15px;
    display: flex;
    // flex-wrap: wrap;
    // flex-direction: row;
    .headerForm--icon{
        width: 50px;
        height: 50px;
        margin: 10px;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url('../../../assets/img/ico-escudo-reposado.svg');
        img{
            width: 40px;
            height: 40px;
        }
        .claso{
            width: 40px;
            height: 40px;
        }
    }
    .headerForm--title{
        padding: 2% 0;
        h2{
            font-size: var(--font-size-primary);
            font-weight: 500;
        }
        h4{
            font-size: var(--font-size-secondary);
        }
    }
}